import { motion } from "framer-motion";
import "./footer.css";
const Footer = () => {
  return (
    <footer className="footer text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 mb-5 mb-lg-0">
            <h4 className="text-uppercase mb-4">Location</h4>
            <p className="lead mb-0">Follonica (GR), Italy</p>
          </div>
          <div className="col-lg-4 mb-5 mb-lg-0">
            <h4 className="text-uppercase mb-4">Social</h4>
            <motion.a
              className="btn btn-outline-light btn-social mx-1"
              href="https://www.instagram.com/pf_software/"
              target="blank"
              whileHover={{ scale: 1.2 }}
            >
              <i className="fab fa-fw fa-instagram"></i>
            </motion.a>
            <motion.a
              className="btn btn-outline-light btn-social mx-1"
              href="https://www.linkedin.com/in/francesco-pagni-3b955819a/"
              target="blank"
              whileHover={{ scale: 1.2 }}
            >
              <i className="fab fa-fw fa-linkedin-in"></i>
            </motion.a>
          </div>
          <div className="col-lg-4">
            <h4 className="text-uppercase mb-4">Progetti</h4>
            <p className="lead mb-0">
              Visita la mia pagina instagram{" "}
              <a href="https://www.instagram.com/pf_software/" target="blank">
                @pf_software
              </a>{" "}
              per vedere alcuni progetti che ho realizzato
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
