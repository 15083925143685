import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";

const Contact = () => {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const HandleSubmit = async (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_po62ksb",
        "template_hhv241h",
        form.current,
        "h3eCCb-Ve5KvEgvMh"
      )
      .then(
        (result) => {
          alert("Messaggio inviato con successo!");
        },
        (error) => {
          alert("Si è verificato un errore con l'invio del messaggio!");
        }
      );
    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <section className="page-section" id="contact">
      <div className="container">
        <h2 className="page-section-heading text-center text-uppercase mb-0">
          <strong>Contattami</strong>
        </h2>
        <div className="divider-custom">
          <div className="divider-custom-line"></div>
          <div className="divider-custom-icon">
            <i className="fas fa-star"></i>
          </div>
          <div className="divider-custom-line"></div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-xl-7">
            <form id="contactForm" ref={form} onSubmit={HandleSubmit}>
              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  type="text"
                  id="name"
                  name="user_name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Nome"
                  required
                />
                <label htmlFor="name">Nome</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  type="email"
                  id="email"
                  name="user_email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  required
                />
                <label htmlFor="email">Indirizzo Email</label>
              </div>
              <div className="form-floating text-area mb-3">
                <textarea
                  className="form-control"
                  name="message"
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Messaggio"
                  required
                ></textarea>
                <label htmlFor="message">Messaggio</label>
              </div>
              <button
                className="btn btn-primary btn-xl"
                id="submitButton"
                type="submit"
              >
                Invia
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
