import { Fragment } from "react";
import "./App.css";
import NavBar from "./layout/navbar";
import Header from "./layout/header";
import "bootstrap/dist/css/bootstrap.min.css";
import Portfolio from "./Section/Portfolio";
import About from "./Section/About";
import Contact from "./Section/Contact";
import Footer from "./layout/footer";
import { Analytics } from "@vercel/analytics/react";

import website_logo from "./assets/img/website.png";
import app_logo from "./assets/img/application.png";
import multidevice_logo from "./assets/img/multidevice.png";
import consulting_logo from "./assets/img/consulting.png";

function App() {
  const solutions = [
    {
      title: "Siti Web",
      img: website_logo,
      imgStyle: "portfolio-img",
      description: `Per i siti web mi affido a Framework come React, Angular e Next.js, questi, permettono di creare siti web moderni e interattivi,
       sfruttando il concetto di componenti riutilizzabili per costruire interfacce user-friendly.
      Tutti i framework offrono strumenti e librerie potenti che semplificano lo sviluppo e migliorano l'efficienza del codice.`,
    },
    {
      title: "Applicazioni Web / Native",
      img: app_logo,
      imgStyle: "portfolio-img",
      description: `Per le applicazioni Windows, utilizzo C# e .NET.
        Questi linguaggi di programmazione offrono un'ampia gamma di funzionalità e librerie che permettono di creare software desktop performanti e scalabili.
       Si possono sviluppare interfacce utente intuitive e personalizzate, utilizzando le risorse offerte da .NET Framework o .NET Core. In alternativa Angular, permette di creare
       applicazioni Web altrettanto perfomanti, con dei vantaggi, come per esempio l'utilizzo di risorse del server che ospità l' applicazione anziché della macchina dell' utente.`,
    },
    {
      title: "Applicazioni Multidevice",
      img: multidevice_logo,
      imgStyle: "portfolio-img",
      description: `Per le applicazioni smartphone e tablet, sia per Android che per iOS, utilizzo React Native.
      Questo framework consente lo sviluppo di app native, mantenendo la semplicità di sviluppo di un sito web. 
      L' utilizzo di questo framework constente di ottenere un maggiore grado di efficienza e ridurre i tempi di sviluppo.
      `,
    },
    {
      title: "Consulenza Informatica",
      img: consulting_logo,
      imgStyle: "",
      description: `Sono un consulente informatico con competenze specializzate sia nel front-end che nel back-end.
       Mi dedico alla creazione di soluzioni personalizzate per realizzare interfacce utente intuitive e sviluppare applicazioni web robuste.
        Se hai bisogno di consulenza informatica o di sviluppo software su misura, non esitare a contattarmi per discutere delle tue necessità!`,
    },
  ];
  return (
    <Fragment>
      <NavBar></NavBar>
      <Header></Header>
      <Portfolio solutions={solutions}></Portfolio>
      <About></About>
      <Contact></Contact>
      <Footer></Footer>
      <Analytics />
    </Fragment>
  );
}

export default App;
