import "./About.css";
const About = () => {
  return (
    <section className="page-section bg-primary text-white mb-0" id="about">
      <div className="about container">
        <h2 className="page-section-heading text-center text-uppercase text-white">
          <strong>About</strong>
        </h2>
        <div className="divider-custom divider-light">
          <div className="divider-custom-line"></div>
          <div className="divider-custom-icon">
            <i className="fas fa-star"></i>
          </div>
          <div className="divider-custom-line"></div>
        </div>
        <div className="row">
          <div className="col-lg-4 ms-auto">
            <p className="lead">
              Come Full Stack Developer, mi occupo della realizzazione di
              prodotti informatici che includono siti web, applicazioni Windows
              e applicazioni per Android / iOS. Questo significa che ho
              competenze sia nel lato front-end, ovvero nell'interfaccia utente
              e nell'esperienza di utilizzo, che nel lato back-end, che riguarda
              la gestione dei dati e la logica del sistema.
            </p>
          </div>
          <div className="col-lg-4 me-auto">
            <p className="lead">
              Utilizzo tecnologie come React e Angular per lo sviluppo di siti
              web, mentre per le applicazioni Windows mi affido a linguaggi come
              C# e .NET. Per quanto riguarda le app per smartphone di nuovo
              React e Angular. Questi framework consentono lo sviluppo di app
              native, mantenendo la stessa base di codice di un sito web
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
