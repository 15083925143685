import "./Modal.css";
import { motion } from "framer-motion";
const Modal = (props) => {
  const onClose = () => {
    props.onClose();
  };
  return (
    <motion.div className="modal" exit={{ opacity: 0 }} key="modal">
      <motion.div
        className="modal-content"
        key="modal-content"
        initial={{
          y: -500,
          x: -300,
          rotate: -25,
          scale: [0.2, 0.5],
          opacity: 0,
        }}
        animate={{
          translateY: 500,
          translateX: 300,
          rotate: 0,
          scale: 1,
          opacity: 1,
        }}
        exit={{
          translateY: -500,
          translateX: 600,
          rotate: 50,
          scale: [0.5, 0.2],
          opacity: 0,
        }}
        transition={{ duration: 0.5, bounce: 0.4, type: "spring" }}
      >
        <div className="modal-header">
          <h2>{props.content.title}</h2>
          <button className="close" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="modal-body">
          <img src={props.content.img} alt={props.content.title} />
          <p>{props.content.description}</p>
        </div>
        <div className="modal-footer">
          <button className="btn" onClick={onClose}>
            Chiudi
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Modal;
