import { motion } from "framer-motion";
import "./navbar.css";
import logo from "../assets/pfsoftware.png";
const NavBar = () => {
  return (
    <nav
      className="navbar navbar-expand-lg text-uppercase fixed-top"
      id="mainNav"
    >
      <div className="container">
        <motion.a
          className="navbar-brand"
          href="#page-top"
          whileHover={{ scale: 1.1 }}
        >
          <img src={logo} alt="logo pfsoftware" className="img-fluid" />
          PF Software
        </motion.a>
        <button
          className="navbar-toggler text-uppercase font-weight-bold text-white rounded"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          Menu
          <i className="fas fa-bars"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ms-auto">
            <motion.li
              className="nav-item mx-0 mx-lg-1"
              whileHover={{ scale: 1.2 }}
            >
              <a
                className="nav-link py-3 px-0 px-lg-3 rounded"
                href="#portfolio"
              >
                Soluzioni
              </a>
            </motion.li>
            <motion.li
              className="nav-item mx-0 mx-lg-1"
              whileHover={{ scale: 1.2 }}
            >
              <a className="nav-link py-3 px-0 px-lg-3 rounded" href="#about">
                About
              </a>
            </motion.li>
            <motion.li
              className="nav-item mx-0 mx-lg-1"
              whileHover={{ scale: 1.2 }}
            >
              <a className="nav-link py-3 px-0 px-lg-3 rounded" href="#contact">
                Contatti
              </a>
            </motion.li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
