import { useState } from "react";
import {
  AnimatePresence,
  motion,
  useScroll,
  useTransform,
} from "framer-motion";
import "./Portfolio.css";
import PortfolioItem from "./PortfolioItem";
import Modal from "../layout/Modal";
const Portfolio = (props) => {
  const [modalOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState(null);

  const { scrollX } = useScroll();
  const scaleText = useTransform(scrollX, [0, 200], [1, 2]);

  const openModal = (props) => {
    setContent(props);
    setIsOpen(true);
  };
  const closeModal = () => {
    setContent(null);
    setIsOpen(false);
  };

  const Items = props.solutions.map((s) => (
    <div className="col-md-6 col-lg-4 mb-5" key={s.title}>
      <PortfolioItem
        title={s.title}
        img={s.img}
        alt={s.title}
        imgClass={s.imgStyle}
        description={s.description}
        openModal={openModal}
      />
    </div>
  ));
  return (
    <section className="page-section portfolio" id="portfolio">
      <div className="container">
        <motion.h2
          className="page-section-heading text-center text-uppercase mb-0"
          style={{ scale: scaleText }}
        >
          <strong>Soluzioni</strong>
        </motion.h2>
        <div className="divider-custom">
          <div className="divider-custom-line"></div>
          <div className="divider-custom-icon">
            <i className="fas fa-star"></i>
          </div>
          <div className="divider-custom-line"></div>
        </div>
        <div className="row justify-content-center">{Items}</div>
      </div>
      <AnimatePresence>
        {modalOpen && <Modal content={content} onClose={closeModal} />}
      </AnimatePresence>
    </section>
  );
};

export default Portfolio;
