import { motion, useScroll, useTransform } from "framer-motion";
import "./header.css";
import avatar from "../assets/avataaars.svg";
const Header = () => {
  const { scrollY } = useScroll();
  const scaleText = useTransform(scrollY, [0, 200], [1, 0.5]);
  const scaleImage = useTransform(
    scrollY,
    [0, 200, 250, 300],
    [1, 1.35, 1.15, 1]
  );
  const yImage = useTransform(scrollY, [0, 300], [0, 240]);
  const opacityText = useTransform(scrollY, [0, 200], [1, 0]);
  const yText = useTransform(scrollY, [0, 200], [0, 130]);
  const ySpan = useTransform(scrollY, [0, 200], [0, 60]);
  const scaleSpan = useTransform(scrollY, [0, 200], [1, 0.7]);
  return (
    <header className="masthead text-white text-center">
      <div className="container d-flex align-items-center flex-column">
        <motion.img
          style={{ scale: scaleImage, y: yImage }}
          className="masthead-avatar mb-5"
          src={avatar}
          alt="avatar pf software"
        />
        <motion.h1
          className="masthead-heading text-uppercase mb-0"
          style={{ scale: scaleText, opacity: opacityText, y: yText }}
        >
          <strong>Francesco Pagni</strong>
        </motion.h1>
        <motion.div
          className="divider-custom divider-light"
          style={{ opacity: opacityText, scale: scaleSpan, y: ySpan }}
        >
          <div className="divider-custom-line"></div>
          <div className="divider-custom-icon">
            <i className="fas fa-star"></i>
          </div>
          <div className="divider-custom-line"></div>
        </motion.div>
        <motion.h3
          className="masthead-subheading font-weight-light mb-0"
          style={{ opacity: opacityText, scale: scaleSpan }}
        >
          Full Stack Developer
        </motion.h3>
      </div>
    </header>
  );
};

export default Header;
