import "./PortfolioItem.css";
const PortfolioItem = (props) => {
  const openModal = () => {
    props.openModal(props);
  };
  return (
    <div className={`portfolio-item mx-auto`} onClick={openModal}>
      <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
        <div className="portfolio-item-caption-content text-center text-white">
          <i className="fas fa-plus fa-3x"></i>
        </div>
      </div>
      <h2>{props.title}</h2>
      <img
        className={`${props.imgClass} img-fluid`}
        src={props.img}
        alt={props.alt}
      />
    </div>
  );
};

export default PortfolioItem;
